@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 979px) {
}

@media screen and (max-width: 764px) {
}

@media (max-width: 767px) {
  .pdf_container .container {
    padding: 0;
  }

  .pdf_container canvas {
    width: 100% !important;
  }
  .circle-img {
    min-width: 0;
  }
  .slide.selected.previous > div {
    width: 100vw;
  }

  .col.d-flex.overflow-auto::-webkit-scrollbar {
    display: none;
  }

  .col.d-flex.overflow-auto {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #basic-menu .MuiMenu-paper {
    left: calc(100% - 253px) !important;
    /* top: auto !important; */
  }
}

@media screen and (max-width: 414px) {
  .login-section label,
  .Payment label {
    font-size: 14px !important;
  }
  .login-section .sub-btn button,
  .user-section .sub-btn button {
    padding: 0;
  }

  /* .MuiStepper-root.MuiStepper-vertical,*/
  .TrackStatus .css-u75is7 .MuiStepConnector-root {
    top: 50% !important;
    transform: translate(0px, -50%) !important;
    height: 80% !important;
  }
  .css-u75is7 .MuiStepConnector-line {
    border-left: 2px dashed rgb(174, 174, 174) !important;
  }
  .MuiStepLabel-iconContainer img {
    background-color: #fff;
  }
  .updateAddress {
    position: fixed;
    right: 14px;
    width: 40px;
    height: 40px;
    line-height: 2;
    text-align: center;
  }

  .user-section {
    height: calc(100vh - 310px);
  }
  .user-section.OrderDetailCon {
    height: calc(100vh - 220px);
  }
  .cart-screen .link {
    line-height: 1.9;
  }

  /* .login-section.OtpVerifyMs {
    min-height: 100vh;
  } */

  .item-details .selectors {
    padding-bottom: 100px;
  }

  .add-items_sec .item-details {
    /* height: calc(100vh - 370px); */
  }

  .group .selectInput.twoInp .MuiOutlinedInput-root {
    margin: 0;
  }

  
.toolbar-vh .user-section { height: calc(100vh - 200px);}
/* .toolbar-vh .user-section {
    height: calc(100vh - 180px);
  } */
  /* .user-section {
    height: calc(100vh - 110px);
    background: #ddd;
  } */
  /* body #root.ios-device.toolbar-vh {
    max-height: calc(100vh - 102px);
  } */
  body #root.ios-device.toolbar-vh {
    /* max-height: calc(100vh - 88px); */
  }
  .bottom-btn-sec {
    bottom: 15px;
  }
  body #root.ios-device {
    /* height: 100vh; */
    /* max-height: calc(100vh - 180px);     */
    /* max-height: calc(100vh - 166px); */
  }

  /*body #root.ios-device {
    height: 100vh;
    max-height: calc(100vh - 86px);
  }*/
  .toolbar-vh .scroll_active .menu-sections {
    /* height: calc(100vh - 65px); */
  }
}

@media screen and (min-width: 500px) {
  body.white {
    background: #fff;
  }
  .wrapper {
    padding: 0px 25px;
  }
  .right-logo img {
    float: right;
  }
  /* .custom_pb .bottom-sec {
    left: 7%;
    max-width: 87%;
  } */
  header .col-9 {
    padding-left: 0px;
  }
  .MuiInputAdornment-root img {
    width: 25px;
  }
  .MuiInputAdornment-root img.visa-icon {
    width: 60px;
  }
  .MuiModal-root.greyBg .MuiPaper-root {
    background-color: #f5f6f7;
  }
}


@media screen and (min-width: 767px) {
  .custom_pb .bottom-sec {
    /* left: 25%;
    max-width: 50%; */
    max-width: 767px;
    margin: 0 auto !important;
  }
  /* .cartDiv{
    right:45px
  } */
}

@media screen and (max-width: 340px) {
  .group .selectInput.twoInp .MuiOutlinedInput-root {
    margin: 10px 0 0 0;
    width: 100%;
  }
}
.MuiStepLabel-iconContainer img {
  background-color: #fff;
}



                                                          /* NEW CSS */



@media screen and (max-width: 550px){
.wrapper.custom_pb {
    padding: 0px !important;
}
}


@media screen and (min-width: 600px) and (max-width: 767px){
.wrapper.custom_pb {
    padding: 0px 10px !important;
}
.wrapper.custom_pb .menu_sec {
    justify-content: end;
}
}

@media screen and (max-width: 767px){
   .scroll_active .cart_sidebar .menu-sections {
    height: inherit;
    padding-bottom: 0px;
  }
  .home-screen .cart_sidebar header {
     padding: 0px 0 0;
}
  .cart_sidebar .orderSummary{
  background: #f5f6f7;
}
  .laptop-device .width-30{
    display: none !important;

}}

@media screen and (max-width:768px){
  .filter-class-f{
    display: none !important;
  }
  .d-md-hidden{
    display: none;
  }
  .mx-mobile-4{
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }
  .mt-pay-4{
    margin-top: 1rem!important;
  }
  .special-promotion .special-pr-0{
    padding-left: 0px;
    padding-right: 0px;
  }
   .wrapper.add_item_sidebar{
  padding: 0px !important;
  }
  .laptop-device .empty-basket-card{
    display: none;
}}

@media (min-width: 768px) and (max-width: 1300px){
 /* .laptop-device .search-bar.input-text{
    width: 50%;
  }*/
  .laptop-device .font-size-h6 .h6{
    font-size: 11px;
  }
  .laptop-device .bcrum .breadcrumb-item {
    font-size: 12px;
    font-weight: 700;
  }
  .laptop-device .bcrum .breadcrumb-item+.breadcrumb-item {
    padding-left: 0.4rem;
}}

@media (min-width:768px) and (max-width:1023px){
  .laptop-device .is-sticky-cart{
    width: 36%;
  }
  .laptop-device .other-options{
    padding:0px;
    font-size: 10px;
  }
  .laptop-device .cart-screen .price-right{
    text-align: right !important;
  }
  /*.laptop-device .main-box-new figure.menu-img{
    min-width: initial;
  }*/
  .laptop-device .main-box-new figure.menu-img{
    min-width: initial;
    width: 48px;
    height: 55px;
  }
  .laptop-device .menu-box .row {
    padding: 12px 12px !important;
}
.laptop-device .main-box-new.menu-box .menu-content{
  padding-left: 12px !important;
}
  .laptop-device .menu-img img{
    object-fit: contain;
  }
  .laptop-device .main-box-new{
    padding-right: 0px;
  }
  .laptop-device #basic-menu .MuiMenu-paper {
/*    left: calc(100% - 275px) !important;*/
    left: calc(100% - 300px) !important;
  }
  .laptop-device .hotel_name .naming-sec{
    margin-left: 0px;
  }
  .laptop-device .countrySelect .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    margin-left: -13px !important;
    font-size: 18px;
  }
  .laptop-device  .height-manage {
    justify-content: flex-start;
  }
  .laptop-device .cd-px-md-4{
    padding: 0 8px !important;
  }
  .laptop-device .padding-left-0{
    padding-left: 0px;
  }
  .laptop-device .offset-4{
    margin-left: 0px;
  }
  .laptop-device .btm-btn-pl-5{
    padding-left: 4px !important;
  }
  .laptop-device .btm-btn-pr-5{
    padding-right: 4px !important;
   }
  .laptop-device .btm-btn-sz .group.sub-btn button{
    font-size: 14px;
  }
  .laptop-device .wrap-px-0{
    padding: 0px 15px;
  }
  .laptop-device .billing-list.cd-px-md-4 li {
    height: inherit;
  }
  .laptop-device .width-30 .orderSummary .outerScroll h3 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .laptop-device .billing-details {
    padding-bottom: 50px !important;
  }
  .laptop-device .cd-pt-md-4{
    padding-top: 21px !important;
  }
  .laptop-device .r-flex-issue{
    display: block;
  }
  .laptop-device .manage-wh figure.menu-img{
    min-width: 45px;
    height: 45px;
  }
  .laptop-device .cart-sec-ms .menu-content h4 {
    font-size: 12px;
  }
  .laptop-device .pricing-lg{
    font-size: 15px;
    text-align: right;
  }
  .laptop-device .padding-right-0{
    padding-right:0px  !important;
  }
  .laptop-device .new-bcrumb li{
    padding-right: 5px;
  }
  .laptop-device .new-bcrumb li + li::before {
    color: #000;
    content: "\02758";
    padding-right: 5px;
  }
  .laptop-device .new-bcrumb .before-border::before {
    color: #000;
    content: "\02758";
    padding-right: 5px;
  }
  .laptop-device .change-header {
    color: #39B5B4;
    padding: 12px 0px 12px 0px;
    font-weight: bold;
  }
  .laptop-device .main-box-new .menu-content h4{
     max-width: 100%;
  }
  .laptop-device .menu_sec{
    margin-bottom: 0px;
    justify-content:  flex-start;
    margin-right: 20px;
  }
 /* .laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    position: fixed;
    bottom: 10px;
    width: 29.7%
  }
  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 24%;
  }*/
/*    .laptop-device .new-delivery-btn .bottom-sec{
  width: 24% !important;
}*/

.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec p{
   font-size: 11px;
  }
  .laptop-device .bcrum .breadcrumb-item svg{
    width: 20px;
  }
  .laptop-device .bcrum .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.3rem;
  }
  .laptop-device .bcrum .breadcrumb-item+.breadcrumb-item {
    padding-left: 0.3rem;
  }
  .laptop-device .bcrum .breadcrumb-item{
    font-size: 10px;
}}

@media (min-width: 900px) and (max-width: 1000px){
  .laptop-device #basic-menu .MuiMenu-paper {
/*    left: calc(100% - 262px) !important;*/
 left: calc(100% - 300px) !important;
}
}

@media (min-width: 914px) and (max-width: 1000px){
  .laptop-device #basic-menu .MuiMenu-paper {
/*    left: calc(100% - 262px) !important;*/
 left: calc(100% - 316px) !important;
}
}

@media (min-width:1024px) and (max-width:1200px){
  .laptop-device .is-sticky-cart{
    width: 29%;
  }
  .laptop-device .side-bar{
    left: 15px;
    height: calc(100% - 60px);
  }
  .laptop-device .main-box-new figure.menu-img{
    min-width: initial;
    width: 75px;
    height: 75px;
  }
  .laptop-device .menu-img img{
    object-fit: contain;
  }
  .laptop-device .menu-box .row{
    min-height: 130px !important;
    padding: 20px 18px;
  }
  .laptop-device .main-box-new .menu-content h4{
    max-width: 100%;
  }
  .laptop-device .add-items_sec .item-details .selectors {
    padding-bottom: 40px !important;
  }
 /* .laptop-device .add-items_sec .item-details {
    height: calc(100vh - 500px);
  }
*/  .laptop-device .padding-left-0{
    padding-left: 0px;
  }
  .laptop-device .hotel_name.con_back .naming-sec{
    margin-left:25px;
  }
  .laptop-device .height-manage .countrySelect .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    margin-left: -22px !important;
  }
  .laptop-device .manage-wh figure.menu-img{
    min-width: 50px;
    height: 50px;
  }
  .laptop-device .width-30 .orderSummary .outerScroll h3{
    padding-top: 0px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .laptop-device  .height-manage.OtpVerifyMs label > span{
    margin-right: 0px;
  }
  .laptop-device .height-manage.OtpVerifyMs #user-mobile{
    width: 100%;
  }
  .laptop-device .cd-px-md-4{
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .laptop-device .b-size{
    font-size: 14px;
  }
  .laptop-device .offset-4{
    margin-left: 0px;
  }
  .laptop-device .btm-btn-pl-5{
    padding-left: 10px !important;
  }
  .laptop-device .btm-btn-pr-5{
    padding-right: 10px !important;
  }
  .laptop-device .btm-btn-sz .group.sub-btn button{
    font-size: 16px;
  }
  .px-lg-00{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .laptop-device .menu_sec{
    margin-bottom: 0px;
    justify-content:  flex-start;
    margin-right: 0px;
  }
  .laptop-device .new-bcrumb .before-border::before {
    color: #000;
    content: "\02758";
    padding-right: 12px;
  }
  .laptop-device .menu-sections .title{
    padding-top: 5px;
  }
  .laptop-device #basic-menu .MuiMenu-paper{
        left: calc(100% - 315px) !important;
  }
  .laptop-device .bcrum .breadcrumb-item svg{
    width: 20px;
}}


@media (min-width: 1170px) and (max-width: 1210px){
  .laptop-device #basic-menu .MuiMenu-paper {
/*    left: calc(100% - 262px) !important;*/
 left: calc(100% - 330px) !important;
}
}

@media (min-width: 1199px) and (max-width: 1230px){
    .laptop-device #basic-menu .MuiMenu-paper{
        left: calc(100% - 345px) !important;
  }
}


@media (min-width:1024px) and (max-width:1300px){
  .laptop-device .orderSummary {
    max-height: calc(100vh - 300px);
  }
/*  .laptop-device .wrapper.cart_sidebar .height-manage .orderSummary{
  max-height: calc(100vh - 330px);
}*/
  .laptop-device .width-30 .orderSummary .outerScroll h3 {
    padding-top: 15px;
  }
  .laptop-device .cart-screen .price-right{
    text-align: right !important;
  }
  .laptop-device .other-options{
    padding:0px;
    font-size: 10px;
  }
/*  .laptop-device #basic-menu .MuiMenu-paper {
    left: calc(100% - 275px) !important;
  }*/
  .laptop-device .col-style-flex{
    display: flex !important;
    justify-content: flex-start !important;
  }
  .laptop-device .change-header {
    color: #39B5B4;
    padding: 12px 0px 12px 6px;
    font-weight: bold;
  }
  /*.laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    position: fixed;
    bottom: 10px;
    width: 29%
  }*/
 /*.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 24%;
  }*/
  /*  .laptop-device .new-delivery-btn .bottom-sec{
  width: 24% !important;*/
/*}*/

.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec p{
   font-size: 13px;
  }
  .laptop-device .i-pr-00{
    padding-right: 0px;
}}

/*@media (min-width: 1200px) and (max-width: 1320px){
  .laptop-device #basic-menu .MuiMenu-paper {
    left: calc(100% - 275px) !important;
}
}*/


@media (min-width:769px) and (max-width:1200px){
  .laptop-device .filter-class{
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .laptop-device .filter-class-f{
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .laptop-device .menu-box .row {
    background: white;
    padding: 15px 15px;
    margin-bottom: 10px;
    min-height: 130px;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
  }
  .laptop-device .new-items-num {
    width: 17px;
    font-size: 17px !important;
  }
  .laptop-device .cart-sec-ms .menu-content h4 {
    font-size: 14px;
  }
  .laptop-device .circle-img figure{
    width: 75px;
    height: 75px;
  }
  .laptop-device .lmr-65{
    margin-left: 15px;
}}

@media (min-width:1060px) and (max-width:1101px){
  .laptop-device .new-add-remove svg {
    /*  width: 10px;*/
    transform: translate(0px , 0px) !important;
  }
  .laptop-device .new-add-add svg {
    /*  width: 10px;*/
    transform: translate(0px , 0px) !important;
}}

@media (min-width:1250px) and (max-width:1300px){
  .laptop-device .cart-screen figure.menu-img{
    min-width: initial;
  }
  .laptop-device .hotel_name.con_back .naming-sec{
    margin-left:25px;
  }
  .laptop-device .cart-screen .price-right{
    text-align: right !important;
  }
  .laptop-device .other-options{
    padding:0px;
    font-size: 10px;
  }
  .laptop-device .col-style-1{
    max-width: 13.666667% !important;
    flex: 0 0 13.666667% !important;
  }
  .laptop-device .col-style-4{
    max-width: 23% !important;
    flex: 0 0 auto !important;
  }
  .laptop-device .orderMenu {
    padding: 0px 15px 20px 15px;
  }
  .laptop-device .main-box-new .menu-content h4{
    max-width: 100%;
  }
  .laptop-device .new-bcrumb .before-border::before{
    padding-right: 8px;
  }
 /* .laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    position: fixed;
    bottom: 10px;
    width: 22%
  }
  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 23% !important;
  }
    .laptop-device .new-delivery-btn .bottom-sec{
  width: 23% !important;
}  */
  .laptop-device .orderSummary {
    max-height: calc(100vh - 300px);
  }
/*  .laptop-device .wrapper.cart_sidebar .height-manage .orderSummary{
  max-height: calc(100vh - 350px);
}*/
  .laptop-device .cart-sec-ms .menu-content h4 {
    font-size: 14px;
  }
  .laptop-device .menu-box .row{
    min-height: 125px;
  }
  .laptop-device .menu-sections .title{
    padding-top: 5px;
  }
  .laptop-device .bcrum .breadcrumb-item svg{
    width: 17px;
}}

@media (min-width:1301px){
  .px-lg-00{
    padding-left: 0px !important;
    padding-right: 0px !important;
}}

@media (min-width:1310px) and (max-width:1380px){
  .laptop-device .new-bcrumb .before-border::before {
    color: #000;
    content: "\02758";
    padding-right: 4px;
  }
  .laptop-device .menu-sections .title{
    padding-top: 5px;
  }
 /* .laptop-device .width-30 .white-bg .bottom-sec .sub-btn{
  position: fixed;
  bottom: 10px;
  width: 21.9%;
  }
  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 21.7%
  }
  .laptop-device .new-delivery-btn .bottom-sec{
  width: 21.7% !important;
}*/
  .laptop-device .new-bcrumb li {
    padding-right: 6px;
    font-weight: bold;
    color: #000;

}}

@media (min-width: 1435px) and (max-width: 1500px){
  .laptop-device #basic-menu .MuiMenu-paper{
/*     left: calc(100% - 350px) !important; old  */
 left: calc(100% - 365px) !important;
}
}

@media (min-width: 1450px) and (max-width: 1500px){
  .laptop-device #basic-menu .MuiMenu-paper{
     left: calc(100% - 365px) !important;
}
}

@media (min-width:1450px) and (max-width:1500px){
  .laptop-device .new-bcrumb li {
    padding-right: 8px;
    font-weight: bold;
    color: #000;
  }
  .laptop-device .new-bcrumb .before-border::before {
    color: #000;
    content: "\02758";
    padding-right: 7px;
  }
 
  .laptop-device .cart-sec-ms .menu-content h4 {
    font-size: 16px;
  }
  .laptop-device .orderSummary {
    max-height: calc(100vh - 300px);
  }
/*  .laptop-device .wrapper.cart_sidebar .height-manage .orderSummary{
  max-height: calc(100vh - 350px);
}*/
  .laptop-device .bcrum .breadcrumb-item{
    font-size: 14px;
}}


@media (min-width:1400px) and (max-width:1500px){
/*  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 22%;
  }*/
 /* .laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    width: 22%
  }*/
  /*.laptop-device .new-delivery-btn .bottom-sec{
  width: 22% !important;
}*/}

@media (min-width:900px) and (max-width:950px){
  .laptop-device .menu_sec{
    margin-bottom: 0px;
    justify-content: flex-start;
    margin-right: 13px;
  }
  .laptop-device .new-bcrumb li {
    padding-right: 8px;
  }
  .laptop-device .new-bcrumb .before-border::before {
    color: #000;
    content: "\02758";
    padding-right: 10px;
  }
 /* .laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    position: fixed;
    bottom: 3px;
    width: 30.2%
  }*/
/*  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 24%;
  }
    .laptop-device .new-delivery-btn .bottom-sec{
  width: 24% !important;
}*/

.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec p{
   font-size: 13px;
  }
  .laptop-device .orderSummary {
    max-height: calc(100vh - 300px);
  }
/*  .laptop-device .wrapper.cart_sidebar .height-manage .orderSummary{
  max-height: calc(100vh - 330px);
}*/

  .laptop-device .bcrum .breadcrumb-item svg{
    width: 17px;
}}

@media (min-width:1050px) and (max-width:1100px){
  .laptop-device .menu_sec{
    margin-bottom: 0px;
    justify-content:  flex-start;
    margin-right: -5px;
  }
  .laptop-device #basic-menu .MuiMenu-paper {
/*    left: calc(100% - 286px) !important;*/
/*   left: calc(100% - 333px) !important;*/
 left: calc(100% - 315px) !important;
  }
  .laptop-device .new-bcrumb .before-border::before {
    color: #000;
    content: "\02758";
    padding-right: 17px;
  }
 /* .laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    position: fixed;
    bottom: 3px;
    width: 29%
  }*/
 /* .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 24%;
  }
    .laptop-device .new-delivery-btn .bottom-sec{
  width: 24% !important;
}
*/
.laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec p{
   font-size: 13px;
  }
  .laptop-device .orderSummary {
    max-height: calc(100vh - 300px);
  }
/*  .laptop-device .wrapper.cart_sidebar .height-manage .orderSummary{
  max-height: calc(100vh - 330px);
}*/
  .laptop-device .width-30 .orderSummary .outerScroll h3 {
    padding-top: 15px;
  }
  .laptop-device .i-pr-00{
    padding-right: 0px;
  }
  .laptop-device .new-add-remove svg {
    /*  width: 10px;*/
    transform: translate(-1px , 0px);
  }
  .laptop-device .new-add-add svg {
    /*  width: 10px;*/
    transform: translate(-1px , -1px);
  }
  .laptop-device .new-items-num {
    font-size: 14px !important;
    transform: translate(0px , 1px);
  }
  .laptop-device .height-manage .new-items-num {
    width: 25px !important;
    font-size: 14px !important;
}}

@media (min-width:1065px) and (max-width:1100px){

  .laptop-device #basic-menu .MuiMenu-paper {
/*    left: calc(100% - 286px) !important;*/
left: calc(100% - 333px) !important;
  }

}
@media (min-width: 800px) and (max-width:899px){
  .laptop-device .countrySelect .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    margin-left: -10px !important;
  }
  .laptop-device .countrySelect .MuiSelect-select.MuiSelect-outlined{
    padding-left: 0px !important;
}}

@media (min-width:769px) and (max-width:1023px){
  .laptop-device #home-wrapper{
    padding: 0px;
  }
  .laptop-device .width-30 .white-bg .cart-screen .empty-basket-card .card-body{
    padding:0px;
  } 
  .laptop-device .height-manage .new-add-remove{
   width: 15px !important;
    height: 15px !important;
  }
  .laptop-device .height-manage .new-add-add{
    width: 15px !important;
    height: 15px !important;
  }
   .laptop-device .menu-sections .title{
    padding-top: 5px;
  }
  .laptop-device .col-9-md{
    max-width: 75%;
  }
  .laptop-device .height-manage .new-items-num {
    width: 25px !important;
    font-size: 14px !important;
}}

@media (min-width:1200px) and (max-width:1300px){
/*  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 24%;
  }
  .laptop-device .new-delivery-btn .bottom-sec{
  width: 24% !important;
  }*/
  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec p{
   font-size: 13px;
  }
  .laptop-device .countrySelect .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    margin-left: -28px !important;
  }
/*  .laptop-device .width-30 .white-bg .bottom-sec .sub-btn{
   width: 21.6%;
}*/}

/*@media (min-width:1274px) and (max-width:1331px){
  .laptop-device .menu_sec{
    margin-right: -5px;
}}*/



@media (min-width:2201px) and (max-width:3000px){
/*.laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    width: 23.4%;
}*/
/*  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 23.4%;
  }
    .laptop-device .new-delivery-btn .bottom-sec{
  width: 23.4% !important;
}*/


.laptop-device .wrapper .bottom-sec{
  max-width: initial !important;
}
}

@media (min-width:1800px) and (max-width:2200px){
/*.laptop-device .width-30 .white-bg .bottom-sec .sub-btn {
    width: 23%;
}*/
/*  .laptop-device .width-30.new-delivery-btn .white-bg .bottom-sec .sub-btn{
    width: 23%;
  }
    .laptop-device .new-delivery-btn .bottom-sec{
  width: 23% !important;
}*/
}

/* @media (min-width:1230px) and (max-width:1310px){
  .laptop-device .menu_sec{
    margin-bottom: 0px;
    justify-content: end;
    margin-right: 0px;
  }
  .laptop-device #basic-menu .MuiMenu-paper {
    left: calc(100% - 288px) !important;
}}

@media (min-width:1270px) and (max-width:1305px){
  .laptop-device .menu_sec {
    margin-bottom: 0px;
    justify-content: end;
    margin-right: 14px;
}} */

